import { Constants } from '@adobe/aem-spa-page-model-manager';

import { ExperienceFragment } from '@/components/ExperienceFragment/ExperienceFragment';
import { Header as FeatureHeader } from '@/features/Header';

const Header = (props) => {
    const { disabled } = props;

    props.navigationItems.forEach((item) => {
        item.menuCardComponent = (
            <ExperienceFragment
                cqItems={item.menuCardExperienceFragment[Constants.ITEMS_PROP]}
                cqItemsOrder={item.menuCardExperienceFragment[Constants.ITEMS_ORDER_PROP]}
                {...item.menuCardExperienceFragment}
            />
        );
    });

    if (disabled) {
        return null;
    }

    return <FeatureHeader {...props} />;
};

export default Header;
